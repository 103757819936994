/*
100 - Thin
200 - Extra Light (Ultra Light)
300 - Light
400 - Normal
500 - Medium
600 - Semi Bold (Demi Bold)
700 - Bold
800 - Extra Bold (Ultra Bold)
900 - Black (Heavy)
*/

$font-dir: "/assets/fonts/LibreBaskerville";

@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src:  url($font-dir + '/LibreBaskerville-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Libre Baskerville';
  font-style: italic;
  font-weight: 400;
  src:  url($font-dir + '/LibreBaskerville-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Libre Baskerville';
  font-weight: 700;
  src:  url($font-dir + '/LibreBaskerville-Bold.otf') format('opentype');
}
