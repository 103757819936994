$tango-sky-blue-light: #729fcf;
$tango-sky-blue-medium: #3465a4;
$tango-sky-blue-dark: #204a87;

$tango-aluminium-light1: #eeeeec;
$tango-aluminium-light2: #d3d7cf;
$tango-aluminium-medium1: #babdb6;
$tango-aluminium-medium2: #888a85;
$tango-aluminium-dark1: #2e3436;
$tango-aluminium-dark2: #2e3436;

$text-color: $tango-aluminium-dark1;
$title-color: $tango-aluminium-dark1;
$background-color: white;
$heading-color: $tango-sky-blue-medium;
$link-color: $tango-aluminium-dark1;
$footer-color: $tango-aluminium-medium1;
$form-border-color: $tango-aluminium-medium1;
$taglist-color: $tango-aluminium-light1;
