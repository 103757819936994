/*
100 - Thin
200 - Extra Light (Ultra Light)
300 - Light
400 - Normal
500 - Medium
600 - Semi Bold (Demi Bold)
700 - Bold
800 - Extra Bold (Ultra Bold)
900 - Black (Heavy)
*/

$font-dir: "/assets/fonts/Aestetico";

@font-face {
  font-family: 'Aestetico';
  font-style: normal;
  font-weight: 100;
  src:  url($font-dir + '/Aestetico-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Aestetico';
  font-style: italic;
  font-weight: 100;
  src:  url($font-dir + '/Aestetico-ThinIt.woff2') format('woff2');
}

@font-face {
  font-family: 'Aestetico';
  font-style: normal;
  font-weight: 200;
  src:  url($font-dir + '/Aestetico-ExtraLight.woff2') format('woff2');
}

@font-face {
  font-family: 'Aestetico';
  font-style: italic;
  font-weight: 200;
  src:  url($font-dir + '/Aestetico-ExtraLightIt.woff2') format('woff2');
}

@font-face {
  font-family: 'Aestetico';
  font-style: normal;
  font-weight: 300;
  src:  url($font-dir + '/Aestetico-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Aestetico';
  font-style: italic;
  font-weight: 300;
  src:  url($font-dir + '/Aestetico-LightIt.woff2') format('woff2');
}

@font-face {
  font-family: 'Aestetico';
  font-style: normal;
  font-weight: 400;
  src:  url($font-dir + '/Aestetico-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Aestetico';
  font-style: italic;
  font-weight: 400;
  src:  url($font-dir + '/Aestetico-RegularIt.woff2') format('woff2');
}

@font-face {
  font-family: 'Aestetico';
  font-style: normal;
  font-weight: 500;
  src:  url($font-dir + '/Aestetico-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Aestetico';
  font-style: italic;
  font-weight: 500;
  src:  url($font-dir + '/Aestetico-MediumIt.woff2') format('woff2');
}

@font-face {
  font-family: 'Aestetico';
  font-style: normal;
  font-weight: 600;
  src:  url($font-dir + '/Aestetico-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Aestetico';
  font-style: italic;
  font-weight: 600;
  src:  url($font-dir + '/Aestetico-SemiBoldIt.woff2') format('woff2');
}

@font-face {
  font-family: 'Aestetico';
  font-style: normal;
  font-weight: 700;
  src:  url($font-dir + '/Aestetico-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Aestetico';
  font-style: italic;
  font-weight: 700;
  src:  url($font-dir + '/Aestetico-BoldIt.woff2') format('woff2');
}

@font-face {
  font-family: 'Aestetico';
  font-style: normal;
  font-weight: 800;
  src:  url($font-dir + '/Aestetico-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Aestetico';
  font-style: italic;
  font-weight: 800;
  src:  url($font-dir + '/Aestetico-ExtraBoldIt.woff2') format('woff2');
}

@font-face {
  font-family: 'Aestetico';
  font-style: normal;
  font-weight: 900;
  src:  url($font-dir + '/Aestetico-Black.woff2') format('woff2');
}

@font-face {
  font-family: 'Aestetico';
  font-style: italic;
  font-weight: 900;
  src:  url($font-dir + '/Aestetico-BlackIt.woff2') format('woff2');
}
