@import "fonts";
@import "colors";

html {
    font-size: $default-font-size;
    font-family: $serif-font;
    background-color: $background-color;
    color: $text-color;
}

main {
    padding: 1em;
    margin-bottom: 3em;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7 {
    font-family: $sans-serif-font;
    color: $heading-color;
    font-weight: 500;
}

p {
    text-align: justify;
    hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    line-height: 1.3;
}

a {
    color: $link-color;
    text-decoration: underline;
}

a:hover {
    text-decoration: none;
}

dt {
    margin: 0.5em 0;
    font-family: $sans-serif-font;
    font-weight: 500;
}

figure {
    margin: 3em 0;
}

img {
    max-width: 100%;
}

figcaption {
    font-family: $sans-serif-font;
    font-weight: 200;
    font-size: 0.8em;
    font-style: italic;
    text-align: center;
}

#site-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
    padding: 1em;
    margin: 2em;
    margin-bottom: 0;
    max-width: 40em;
    margin-left: auto;
    margin-right: auto;

    nav {
        flex-grow: 1;
    }
}

#home-nav {
    text-align: center;

    h1 {
        font-family: $serif-heading;
        font-weight: 300;
        margin: 0;
        font-size: 2rem;

        a {
            color: $link-color;
            text-decoration: none;
        }

        a:hover {
            color: $heading-color;
            text-decoration: none;
        }
    }

    h2 {
        font-style: italic;
        font-weight: 200;
        font-size: 0.7rem;
        margin: 0;
        font-family: $sans-serif-font;
        color: $text-color;
    }
}

#site-nav {
    a {
        display: block;
        font-family: $sans-serif-font;
        font-weight: 400;
        font-size: 0.7em;
        text-decoration: none;
        text-transform: uppercase;
    }

    a:hover {
        text-decoration: underline;
    }

    ul {
        margin: 0;
        margin-top: 1em;
        display: table;
        padding: 0;
        width: 100%;
    }

    li {
        list-style-type: none;
        display: table-cell;
        text-align: center;
        padding-right: 0.5em;
        padding-left: 0.5em;
    }
}

#page-title,
#post-title {
    margin: 0;
    text-align: center;
    font-weight: 100;
    font-size: 3em;
    font-style: italic;
    font-family: $sans-serif-font;
}

#pagination {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0;

    a {
        display: inline-block;
        color: $link-color;
        font-family: $sans-serif-font;
        font-weight: 700;
        font-size: 0.7em;
        text-decoration: none;
        text-transform: uppercase;
    }

    a:hover {
        text-decoration: underline;
    }
}

#pagination-prev {
    text-align: left;
}

#pagination-next {
    text-align: right;
}

#pagination-pages {
    text-align: center;
}

#site-footer {
    padding: 3em 0;
    border-top: 0.5px solid $footer-color;
}

#footer-nav {
    ul {
        margin-left: auto;
        margin-right: auto;
        max-width: 20em;
        font-family: $sans-serif-font;
        font-weight: 200;
        font-size: 0.9em;
        padding: 0;
        text-align: center;
    }

    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    li {
        list-style-type: none;
        display: inline-block;
    }

    li:not(:last-of-type)::after {
        content: " – "
    }
}

.blogitem {
    h2 {
        text-align: center;
        font-size: 2rem;
        font-weight: 200;

        a {
            text-decoration: none;
        }

        a:hover {
            color: $heading-color;
        }
    }

    h2::after {
        content: ' / ' attr(data-date);
        font-size: 1.2rem;
    }

    img {
        width: 100%;
        aspect-ratio: 4/3;
        object-fit: cover;
        margin-left: auto;
        margin-right: auto;
        display: block;
        box-shadow: 0px 0px 0.5em rgba(0, 0, 0, 0.3);
    }

    p {
        line-height: 1.6;
    }
}

#post-date {
    font-family: $serif-heading;
    font-size: 0.8em;
    text-align: right;
    font-weight: 600;
    color: $text-color;
}

#post {
    figure {
        padding: 3em;
    }
}

#taglist {
    text-align: center;
    margin: 2em 0;

    span {
        font-family: $sans-serif-font;
        font-size: 0.8em;
        background-color: $taglist-color;
        padding: 0 0.5em;
        border-radius: 0.2em;
        white-space: nowrap;
        hyphens: none;
        -webkit-hyphens: none;
        -ms-hyphens: none;
    }
}

.gallery-figure figcaption {
    display: none;
}

.gallery-head {
    text-align: center;
    margin: 0;
}

#gallery-preview {
    a {
        line-height: 0;
        display: block;
    }

    img {
        opacity: 0.8;
    }

    figure {
        position: relative;
        margin: 0;
    }

    img:hover {
        opacity: 1;
    }

    figcaption {
        left: 0;
        right: 0;
        bottom: calc(50% - 1.5em);
        position: absolute;
        color: $background-color;
        padding: 1em;
        font-family: $serif-heading;
        font-weight: 400;
        font-size: 1.5em;
        pointer-events: none;
        background-color: rgba(0, 0, 0, 0.3);
    }
}

#contactform {
    fieldset {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1em;
        border: 1px dashed $form-border-color;

        label,
        button,
        input {
            font-family: $sans-serif-font;
            font-size: 0.8rem;
        }

        textarea {
            resize: none;
            font-family: $serif-font;
            font-size: 0.8rem;
        }
    }
}

#comments {
    #comment-explain {
        font-size: 0.8rem;
        text-align: center;
    }

    h1 {
        text-align: center;
        margin-top: 4rem;
        margin-bottom: 4rem;
        font-weight: 100;
    }

    h2 {
        font-size: 1.1rem;
        margin-top: 2rem;
    }

    h2::after {
        content: ' / ' attr(data-content);
        font-size: 0.9rem;
        font-weight: 200;
    }
}

#error-message {
    text-align: center;
}

@media (min-width: 768px) {
    main {
        max-width: 40em;
        margin-left: auto;
        margin-right: auto;
    }

    .blogitem {
        margin: 10em 0;
    }

    #page-title,
    #post-title {
        margin: 2em;
    }

    #raw-locator {
        justify-items: center;
        grid-template-columns: 12em auto;
        grid-template-rows: auto;
        grid-template-areas:
            "image download";
    }

    #gallery-preview {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.5em;
    }

    #gallery {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(13em, 1fr));
        grid-auto-rows: 13em;
        grid-auto-flow: dense;
        grid-gap: 0.5em;
    }

    .gallery-figure {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;

        a {
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0.8;
        }

        img:hover {
            opacity: 1;
        }
    }

    .gallery-wide {
        grid-column: span 2;
    }

    .gallery-tall {
        grid-row: span 2;
    }

    .gallery-big {
        grid-column: span 2;
        grid-row: span 2;
    }

    #contactform {
        fieldset {
            width: 66%;
            margin-left: auto;
            margin-right: auto;
            border: 0 none;
        }
    }
}