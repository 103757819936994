/*
100 - Thin
200 - Extra Light (Ultra Light)
300 - Light
400 - Normal
500 - Medium
600 - Semi Bold (Demi Bold)
700 - Bold
800 - Extra Bold (Ultra Bold)
900 - Black (Heavy)
*/

$font-dir: "/assets/fonts/CrimsonText";

@font-face {
  font-family: 'Crimson Text';
  font-style: normal;
  font-weight: 400;
  src:  url($font-dir + '/CrimsonText-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Crimson Text';
  font-style: italic;
  font-weight: 400;
  src:  url($font-dir + '/CrimsonText-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Crimson Text';
  font-style: normal;
  font-weight: 600;
  src:  url($font-dir + '/CrimsonText-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Crimson Text';
  font-style: italic;
  font-weight: 600;
  src:  url($font-dir + '/CrimsonText-SemiBoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Crimson Text';
  font-style: normal;
  font-weight: 700;
  src:  url($font-dir + '/CrimsonText-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Crimson Text';
  font-style: italic;
  font-weight: 700;
  src:  url($font-dir + '/CrimsonText-BoldItalic.woff2') format('woff2');
}
